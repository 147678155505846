.terms-of-use {

  &__wrap {

    &-info {

      &-name {
        padding: 0 0 20px 0;
        color: #ffddba;
      }

      &-text {
        display: flex;
        text-align: left;
      }
    }
  }
}