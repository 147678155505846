.breadcrumbs-wrapper {
  display: flex;
  margin: 5px 0 20px 0;
  flex-wrap: wrap;

  div {
    color: #FFDDBA;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    //font-size: small;
  }

  span {
    color: #FFDDBA;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  &__title {
    color: #FFDDBA;
    margin-right: 5px;
    cursor: pointer;
  }

  .back-blog {
    color: #FFDDBA;
    text-decoration: none;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .back-blog {
    font-size: 12px;
  }

  .breadcrumbs-wrapper {
    div {
      font-size: 12px;
    }

    span {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 600px){
  .breadcrumbs-wrapper {
    display: none;

  }
}
