.price-info-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 98%;
  margin: 10px auto 0 auto;
}
.head-name {
  padding: 30px 20px 20px 0;
  font-size: 30px;
  color: rgba(255, 221, 186, 1)
}

.price-list {
  display: flex;
  width: 99%;
  justify-content: space-between;

  &__general {
    width: 65%;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 10px;
      font-size: 14px;
      border: 1px white solid !important;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      height: 45px;
    }

    &__items {
      display: flex;
      height: 70px;
      overflow: hidden;
      border-radius: 0 0 10px 10px;
      border: 1px solid white;
      border-top: none;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 25%;
      border: 1px solid white;
      border-left: none;
      border-top: none;
      min-height: 70px;
      max-height: 70px;
      height: 100%;
      overflow: hidden;
      border-right: 1px solid #fff;

      &:last-child {
        border-bottom-right-radius: 10px;
        border-right: none;
      }

      &:first-child {
        //border-left: 1px solid white;
        border-bottom-left-radius: 10px;
      }

      &-price {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #000;
        color: #FFDDBA;
        font-size: 14px;
        padding: 10px 0;
        height: 40px;
      }


      &-title {
        width: 100%;
        display: flex;
        justify-content: center;
        background: #000;
        color: white;
        font-size: 14px;
        padding-top: 10px;
        min-height: 30px;
      }
    }

  }

  &__vip {
    width: 32%;
    border: 1px solid white;
    border-radius: 10px;
    overflow: hidden;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
      padding: 10px;
      background: black;
      border-bottom: 1px white solid;
      // border-radius: 5px 5px 0 0;
      min-height: 45px;
      max-height: 45px;
    }

    &-price-metr {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 10px;
    }

    &__item {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &-price {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        color: #FFDDBA;
        font-size: 14px;
        padding: 10px 0;
        border-radius: 0 0 5px 5px;

      }
    }
  }
}

.loader-price {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.price-list_general-attributes-price {
  background: black;
  color: #FFDDBA;
}

@media screen and (max-width: 1295px) {
  .price-list__vip__item-price {
    padding: 11px;
  }
}

@media screen and (max-width: 768px) {
  .price-list__general__item {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // overflow: hidden;
    height: auto;
    display: none;
    //border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
    &:last-child {
      border-right: none !important;
    }
  }
  .price-list_general-attributes-price {
    //background: white;
    color: #FFDDBA;
    padding-left: 5px;
  }
  .price-list__general__title {
    //background: white;
    color: white;
  }
  .price-list__general__item-price {
    //background: white;
    color: #FFDDBA;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .price-list__general__item-title {
    //background: white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-top: 1px solid black;
  }
  .price-list__general__title {
    border-bottom: 1px solid black;
  }
  .price-list__vip__item-price {
    padding: 20px;
    min-height: 70px;
    max-height: 70px;
    color: #FFDDBA;
  }
  .price-list__general__item-title {
    font-size: 13px;
  }
  .price-list__vip__title{
    font-size: 13px;
    color: white;
  }
  .price-list__general__item {
    border-right: 1px solid transparent;
  }
  .price-list__general__item:first-child {
    border-left: none;
  }
  .price-list__general__item {
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  .price-list {
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: center;
  }
  .price-list__general {
    width: 53%;
  }
  .price-list__vip {
    width: 46%;
    border: 1px solid white;
  }
  .head-name {
    font-size: 20px;
  }
}

@media screen and (max-width: 700px) {
  .price-list__general__item-title {
    font-size: 11px;
  }
  .price-list__vip__title{
    font-size: 12px;
  }
  .price-list__vip__item-price {
    padding: 15px;
  }
  .price-list__general__item-price {
    font-size: 11px;
  }
  .price-list__general__title {
    font-size: 12px;
  }
}

@media screen and (max-width: 610px) {
  .price-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .price-list__general__item {
    border: 1px solid white !important;
    border-right: none !important;
    border-top: none !important;
    &:last-child {
      border-right: 1px solid white !important;
    }

    .price-list__general__item-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 5px 0;
    }
  }
  .price-list__general__item-price {
    margin: 0 !important;
  }
  .price-list_general-attributes-price {
    background: black;
    color: #FFDDBA;
    padding-left: 5px;
    //display: none;
  }
  .price-list__general {
    width: 95%;
    border-radius: none !important;
  }
  .price-list__vip {
    width: 95%;
  }
  .price-list__vip__title {
    text-align: center;
  }
  .price-list__vip__title-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price-list__vip__item {
    overflow: hidden !important;
    width: 100%;
  }

  .price-list__vip__title {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 14px;
    padding: 10px;
    background: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 10px !important;
  }

  .price-list__vip__item-price {
    background: black;
    color: #FFDDBA;
    border-bottom-left-radius: 40px !important;
    overflow: hidden !important;
    height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;

  }

  .price-list__vip {
    border: 1px solid white !important;
  }

  .price-list {
    display: flex;
    width: 98%;
    justify-content: space-between;
    padding: 0;
    align-items: center;

    &__general {
      width: 95%;
      margin: 20px 0 30px 0;
      &__title {
        display: flex;
        justify-content: center;
        color: white;
        padding: 10px;
        font-size: 14px;
        background: black;
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .price-list__general__item-title {
    display: flex;
    justify-content: center;
    background: black;
    color: white;
    font-size: 14px;
  }
  .price-list__general__item-price {
    display: flex;
    justify-content: center;
    background: black;
    color: #FFDDBA;
    font-size: 14px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 610px) {
  .price-list__general__item-title {
    padding: 1px;
  }
}

@media screen and (max-width: 420px) {
  .price-list__general__item-price {
    padding: 0;
    font-size: 12px;
  }
  .price-list__general__item-title {
    font-size: 12px;
  }

}

@media screen and (max-width: 350px) {
  .price-list__general__item-price {
    padding: 0;
    font-size: 11px;
  }
  .price-list__general__item-title {
    font-size: 11px;
    padding: 5px !important;
  }


}
