.main-wrapper {
	display: flex;
	justify-content: center;
	height: 100%;
}

.header-filter {
	display: none;
}

.skeleton-slider {
	display: flex;
}

@media screen and (max-width: 1024px) {
	.header-filter {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		//margin: 10px 0;

		&_item {
			margin: 10px;
			display: flex;

			div {
				background: white;
				color: black;
				padding: 5px;
				border-radius: 5px;
				height: 40px;
				display: flex;
				align-items: center;
			}

			img {
				background: white;
				width: 10px;
				height: 10px;
				margin-left: 5px;
			}
		}
	}
}

.main-wrapper-footer-slide {
	width: 95%;
	margin: 0 auto;
	padding-top: 50px;

	.slick-list {
		width: 100% !important;
	}
}


