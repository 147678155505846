.Tabs {
	width: 100%;
	//height: auto;
	min-height: 2000px;
	color: #e8f0f2;

	.selected-tab {
		text-decoration-line: underline;
		color: #ffddba;
	}
}

.outlet {
	width: 100%;
	padding-top: 10px;
}

.bread-crumbs-wrap {
	width: 98%;
}

/* Tab Navigation */
ul.nav {
	width: 60%;
	margin: 1rem auto 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	//border: 1px solid #39A2DB;
	border-radius: 2rem;
	position: absolute;
	top: 0;
}

ul.nav li {
	//width: 50%;
	padding: 1rem;
	list-style: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.7s;
	border-bottom-left-radius: 2rem;
	border-top-left-radius: 2rem;
}

ul.nav li:hover {
	color: #ffddba;
}

ul.nav li:nth-child(2) {
	border-radius: 0;
	border-bottom-right-radius: 2rem;
	border-top-right-radius: 2rem;
}

.FirstTab p,
.SecondTab p {
	font-size: 2rem;
	text-align: center;
}

@media (max-width: 1024px) {
	.Tabs {
		padding: 0;
	}
	ul.nav {
		position: static;
		width: 90%;
		margin: 10px auto;
	}
	ul.nav li {
		width: 48%;
		padding: 0;
	}
	.outlet {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0;
		height: fit-content;
		position: absolute;
		//margin-top: 25px;
	}
}

@media (max-width: 576px) {
	ul.nav li:nth-child(2) {
		border-radius: 0;
		border-bottom-right-radius: 2rem;
		border-top-right-radius: 2rem;
		//width: 300px;
	}
}
