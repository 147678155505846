@import 'src/variables';

.navigation-left {
	//width: 13.25%;
	min-width: 266px;
	max-width: 266px;
	background: #000;
	padding: 0 14px;
	height: 100%;

	ul {
		display: flex;
		flex-direction: column;
	}

	&__item {
		color: #fff;
		text-decoration: none;
		padding-bottom: 15px;
		//width: 9vw;
		//padding-left: 10px;
	}

	.selected {
		color: #ffddba;
	}
}
.mobile {
	display: none;
}

.filter-place-type li {
	position: relative !important;
	padding: 6px 0 !important;
	min-height: 38px;
	display: flex;
	align-items: center;
}

.filter-place-type {
	//max-height: 32vh;
	overflow-y: hidden;
	position: relative;
	padding-left: 3px;

	&__cancel {
		padding-bottom: 11px;
		cursor: pointer;
		min-height: 40px;
		display: flex;
		align-items: center;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 7px;
		border-bottom: 1px white solid;
		margin: 20px 0;
		cursor: pointer;
	}

	li {
		position: relative;
		label {
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 50%;
			cursor: pointer;
			height: 16px;
			left: 0;
			position: absolute;
			//top: 6px;
			width: 16px;
		}

		label:after {
			border: 2px solid #000;
			border-top: none;
			border-right: none;
			content: '';
			height: 6px;
			left: 2px;
			opacity: 0;
			position: absolute;
			top: 1.5px;
			transform: rotate(-45deg);
			width: 8px;
		}

		input[type='checkbox'] {
			visibility: hidden;
		}

		input[type='checkbox']:checked + label {
			background-color: $gold-color;
			border-color: $gold-color;
		}

		input[type='checkbox']:checked + label:after {
			opacity: 1;
		}
		padding-bottom: 11px;

		input {
			margin-right: 8px;
		}
	}
}

.show-all-filter-btn {
	padding: 15px 0;
	cursor: pointer;
	border: none;

	ul {
		padding-left: 19px;
		font-size: 16px;
	}
}
.filter-block {
	display: block;
}

@media screen and (max-width: 1136px) {
	.navigation-left__item {
		//width: 30vh;
	}
}
@media screen and (min-width: 1025px) {
	.navigation-left {
		display: block !important;
	}
}
@media screen and (max-width: 1024px) {
	.filter-block {
		display: none;
	}
	.navigation-left__item {
		//width: 36vh;
	}
	.navigation-left {
		max-width: 100%;
		min-width: 100%;
		padding: 0;
		display: block;

		ul {
			display: flex;
			flex-direction: column;
			//padding-left: 20px;
			//align-items: center	;
		}

		ul.nav {
			margin: 0 auto;
		}
		&__item {
			padding-left: 20px;
		}
	}
	.filter-tabs {
		display: flex;
		flex-direction: row !important;
	}
	//.desktop {
	//	display: none;
	//}
	.mobile-filter {
		width: 28%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 3px;
		//display: flex;
		//align-items: center;
		margin: 3px 0;

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			width: 90%;
			margin: 0 auto;

			&__btn-apply {
				margin: 10px auto;
				display: flex;
				width: 80%;
				padding: 10px;
				color: rgba(0, 0, 0, 0.7);
				border-radius: 5px;
				background: rgba(255, 221, 186, 1);
				font-size: 16px;
				justify-content: center;
				align-items: center;
			}
		}

		&-selected {
			width: 28%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 10px 3px;
			border-radius: 5px;
			background: white;
			color: rgba(0, 0, 0, 0.7);
			margin: 3px 0;
			img {
				background: transparent;
			}
		}
	}
	.filter-place-type {
		&__title {
			justify-content: center;
			border-bottom: none;
			padding-bottom: 0;
			img:nth-child(3) {
				display: none;
			}
		}
	}
}
@media screen and (max-width: 850px) {
	.mobile-filter{
		font-size: 14px;
		text-align: center;
	}
	.mobile-filter-selected {
		font-size: 14px;
		text-align: center;

		&::after {
			content: "";
			background-image: url("../../assets/close_btn.svg");
			height: 12px;
			width: 2px;
			margin-bottom: 3px;
			padding-right: 10px;
		}
	}
}

@media screen and (max-width: 576px) {
	.mobile-filter{
		font-size: 12px;
		text-align: center;
	}
	.mobile-filter-selected {
		font-size: 12px;
		text-align: center;
	}
}
@media screen and (max-width: 400px) {
	.mobile-filter{
		font-size: 10px;
		text-align: center;
	}
	.mobile-filter-selected {
		font-size: 10px;
		text-align: center;
	}
	.filter-place-type__title{
		font-size: 14px;
	}
}

.navigation-left__item:hover {
	color: #ffddba;
	//width: 9vw;
}
//.navigation {
//	&-left {
//		width: 14.4%;
//		&__items {
//			display: flex;
//			flex-direction: column;
//		}
//		&__item {
//			text-decoration: none;
//			padding-bottom: 13px;
//			padding-left: 27px;
//			font-size: 16px;
//		}
//		.selected {
//			color: $gold-color;
//		}
//	}
//}
