@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import './src/variables';

.image-gallery {
  //padding-top: 30px;
}

//.image-gallery-using-mouse {
//  display: flex;
//}

.image-gallery-wrap {

  position: relative;
  overflow: hidden;
  margin-top: 20px;

  .left-nav {
    position: absolute;
    left: 12%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .left-nav-img {
    width: 60px;
    height: 60px;
  }

  .right-nav {
    position: absolute;
    right: 8%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .right-nav-img {
    width: 60px;
    height: 60px;
  }

  .image-gallery-swipe {

    .image-gallery-image {
      //width: 1130px !important;
      height: 800px !important;
      object-fit: contain;
    }
  }

  .image-gallery-image {
    min-height: 800px !important;
  }

  &-galerry {
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: none;
  }

  //.image-gallery-content .image-gallery-slide .image-gallery-image {
  //  max-height: none;
  //}

  .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 68%;
    margin: 0 auto;
  }
  .image-gallery-swipe {
    width: 68%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .image-gallery-right {
    display: none;
  }

  .image-gallery-right-nav {
    padding: 50px 180px;
    //top: 106.5%;
    transform: translateY(-50%);
  }
  .image-gallery-left-nav {
    padding: 50px 180px;
    //top: 106.5%;
    transform: translateY(-50%);
  }

  .image-gallery-thumbnail-image {
    height: 69px !important;
    object-fit: scale-down;
  }

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid #FFDDBA;
  }

  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
  }
  .fullscreen-overlay {
    position: relative;
  }
  .fullscreen-overlay img {
    height: 100vh;
    width: 100%;
    object-fit: contain;
  }

  .fullscreen-overlay-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: transparent;

    img {
      height: 60px;
      width: 60px;
      background: transparent;
    }
  }

  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 67.5%;
  }

  .magnifier-container {
    .magnifier-content {
      div {
        display: flex;
        height: 100vh;

        img {
          object-fit: cover !important;
        }
      }
    }
  }
}

.image-gallery-btn-wrap {
  position: relative;
  &-prev {
    position: absolute;
    bottom: 4px;
    left: 299px;
    height: 78px;
    display: flex;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);

    img {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &-next {
    position: absolute;
    bottom: 9px;
    right: 299px;
    height: 69px;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    img {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 4px solid #FFDDBA !important;
  cursor: pointer;
}

.image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  width: 59.5%;
  margin: 0 auto;
}

.magnifier-container {
  margin-bottom: 50px;
}

.about-repair {
  max-width: 61.5%;
  margin: 15px auto;

  &__wrapper {
    width: 100%;
    //min-height: 950px;

    &-close__btn {
      position: absolute;
      z-index: 2;
      right: 45px;
      top: 20px;
      background: transparent;

      img {
        background: transparent;
      }
    }
  }

  &__description {
    margin: 10px 0;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 160px);
  cursor: zoom-in;
}

.about-repair__mobile-photos,
.about-repair__mobile {
  display: none;
}

@media screen and (max-width: 1800px) {
  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 58%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1750px) {
  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 64%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1640px) {
  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 61.5%;
  }
  .image-gallery-wrap .image-gallery-swipe {
    width: 61.5%;
  }

  .image-gallery-btn-wrap-prev {
    left: 290px;
  }
  .image-gallery-btn-wrap-next {
    right: 293px;
  }
}

@media screen and (max-width: 1630px) {
  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 61%;
  }
  .image-gallery-wrap .image-gallery-swipe {
    width: 61.5%;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 740px !important;
    }
  }
}

@media screen and (max-width: 1550px) {
  .image-gallery-wrap .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    width: 61.5%;
    margin: 0 auto;
  }
  .image-gallery-btn-wrap-prev {
    left: 272px;
  }
  .image-gallery-btn-wrap-next {
    right: 277px;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 705px !important;
    }
  }
}

@media screen and (max-width: 1470px) {
  .image-gallery-btn-wrap-prev {
    left: 260px;
  }
  .image-gallery-btn-wrap-next {
    right: 261px;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 665px !important;
    }
  }
}

@media screen and (max-width: 1370px) {
  .image-gallery-btn-wrap-prev {
    left: 245px;
  }
  .image-gallery-btn-wrap-next {
    right: 244px;
  }
}

@media screen and (max-width: 1320px) {
   .image-gallery-wrap {
     .image-gallery-right-nav {
       padding: 50px 130px;
       //top: 108%;
       transform: translateY(-50%);
     }
     .image-gallery-left-nav {
       padding: 50px 130px;
       //top: 108%;
       transform: translateY(-50%);
     }
   }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 600px !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .image-gallery-btn-wrap-prev {
    left: 222px;
  }
  .image-gallery-btn-wrap-next {
    right: 226px;
  }
}

@media screen and (max-width: 1240px) {
  .image-gallery-btn-wrap-prev {
    left: 222px;
  }
  .image-gallery-btn-wrap-next {
    right: 226px;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 545px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .image-gallery-btn-wrap-prev {
    left: 208px;
  }
  .image-gallery-btn-wrap-next {
    right: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 520px !important;
    }
  }
}

@media screen and (max-width: 1140px) {
  .image-gallery-btn-wrap-prev {
    left: 196px;
  }
  .image-gallery-btn-wrap-next {
    right: 198px;
  }
}

@media screen and (max-width: 1120px) {
  .image-gallery-wrap {
    .image-gallery-right-nav {
      padding: 50px 100px;
      //top: 109%;
      transform: translateY(-50%);
    }
    .image-gallery-left-nav {
      padding: 50px 100px;
      //top: 109%;
      transform: translateY(-50%);
    }

    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
        object-fit: contain;
      }
    }

    .image-gallery-image {
      min-height: 475px !important;
    }
  }


}

@media screen and (max-width: 1080px) {
  .image-gallery-btn-wrap-prev {
    left: 183px;
  }
  .image-gallery-btn-wrap-next {
    right: 187px;
  }
}

@media screen and (max-width: 1024px) {
  .about-repair {
    //width: 95%;
  }
  .image-gallery-left-nav,
  .image-gallery-icon,
  .about-repair__wrapper-close__btn,
  .image-gallery-right-nav {
    display: none;
  }
}

@media screen and (max-width: 1014px) {
  .image-gallery-btn-wrap-prev {
    left: 170px;
  }
  .image-gallery-btn-wrap-next {
    right: 174px;
  }
}

@media screen and (max-width: 980px) {
  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 440px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .image-gallery-btn-wrap-prev {
    left: 160px;
  }
  .image-gallery-btn-wrap-next {
    right: 164px;
  }
}

@media screen and (max-width: 910px) {
  .image-gallery-btn-wrap-prev {
    left: 149px;
  }
  .image-gallery-btn-wrap-next {
    right: 153px;
  }
}

@media screen and (max-width: 860px) {
  .image-gallery-btn-wrap-prev {
    left: 140px;
  }
  .image-gallery-btn-wrap-next {
    right: 143px;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 420px !important;
    }
  }
}

@media screen and (max-width: 814px) {
  .image-gallery-btn-wrap-prev {
    left: 132px;
  }
  .image-gallery-btn-wrap-next {
    right: 135px;
  }

  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 385px !important;
    }
  }
}

@media screen and (max-height: 800px) {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .image-gallery-btn-wrap-prev {
    left: 132px;
    bottom: 2px;
  }
  .image-gallery-btn-wrap-next {
    right: 135px;
    bottom: 7px;
  }
}

@media screen and (max-width: 730px) {
  .image-gallery-btn-wrap-prev {
    left: 116px;
    bottom: 2px;
  }
  .image-gallery-btn-wrap-next {
    right: 119px;
    bottom: 7px;
  }
}

@media screen and (max-height: 700px) {
  .image-gallery-wrap .image-gallery-right-nav {
    padding: 50px 140px;
    //top: 106.5%
    transform: translateY(-50%);
  }
  .image-gallery-wrap .image-gallery-left-nav {
    padding: 50px 140px;
    //top: 106.5%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 680px) {
  .image-gallery-btn-wrap-prev {
    left: 106px;
    bottom: 2px;
  }
  .image-gallery-btn-wrap-next {
    right: 107px;
    bottom: 7px;
  }
}

@media screen and (max-width: 640px) {
  .image-gallery-wrap {
    .image-gallery-swipe {

      .image-gallery-image {
        height: 0 !important;
      }
    }

    .image-gallery-image {
      min-height: 305px !important;
    }
  }
}


@media screen and (max-width: 600px) {
  .about-repair {
    //width: 95%;
    display: none;

    &__mobile {
      display: block;

      &-top {
        display: flex;
        align-items: center;
        padding: 15px 0;
        width: 95%;
        margin: 0 auto;

        &__title {
          font-size: 18px;
          color: $gold-color;
          padding-left: 50px;
        }

        &__desc {
          width: 95%;
          margin: 0 auto;

          &-more {
            color: $gold-color;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
          }
          &-hide {
            color: $gold-color;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            display: flex;
            justify-content: end;

          }
        }
      }

      &-photos {
        display: block;
        width: 95%;
        margin: 0 auto;
      }

      &-photo {
        width: 100%;
      }
    }

    &__wrapper {
      &-close__btn {
        display: none;
      }
    }
  }
  .image-gallery {
    display: none;
  }
}

@media screen and (max-height: 550px) {
  .image-gallery-wrap .image-gallery-right-nav {
    padding: 50px 140px;
    //top: 107%;
    transform: translateY(-50%);
  }
  .image-gallery-wrap .image-gallery-left-nav {
    padding: 50px 140px;
    //top: 107%;
    transform: translateY(-50%);
  }
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
}

.image-gallery-icon, .image-gallery-left-nav, .image-gallery-icon, .image-gallery-right-nav {
  padding: 10px 3%;
}

polyline {
  color: $gold-color;

}
