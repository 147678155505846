@import 'src/variables';

.one-blog {
	//width: 90%;
	width: 82%;

	&__title {
		padding: 10px 0;
		font-size: 28px;
		font-weight: normal;
		//color: rgba(255, 221, 186, 1);
	}
	&__description {
		padding-top: 10px;

	}
	&__date{
		font-size: 14px;
		margin-bottom: 20px;
		color: rgba(255, 221, 186, 1);
	}
	.top-panel {
		display: flex;

		.back__btn {
			display: flex;
			align-items: center;
			//min-height: 1000px;
			img {
				padding-bottom: 3px;
			}
			a {
				padding-left: 5px;
				text-decoration: none;
			}
		}
	}

	&__item {
		//min-height: 700px;

		.rightImg {
			float: right;
			margin: 10px;
			max-height: 700px;
			min-height: 450px;
			//width: 50%;
			width: 565px;
			object-fit: cover;
		}
		&-video{
			width: 93%;
			object-fit: fill;
			max-height: 700px;
			min-height: 450px;
		}
	}
}

.another-blogs {
	width: 95%;
	&__title {
		color: $gold-color;
		padding: 20px 0;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		video {
			width: 100%;
			height: 77%;

			//height: 60%;
			-webkit-transform: scaleX(1.2);
		}

		&__main {
			max-width: 630px;
		}
	}

	a {
		width: 23%;
		text-decoration: none;
	}
}

.footer-blog-info {
	display: flex;
	justify-content: space-between;
	width: 100%;

	&__left {
		display: flex;
		width: 25%;

		&-share {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		div {
			display: flex;
			align-items: end;
		}
	}
	&__center {
		display: flex;
		flex-direction: column;
		width: 50%;

		&-inst-text {
			display: flex;
			align-items: center;
			font-size: 30px;

		}

		&-text {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px 0 40px 0;
			color: #FFDDBA;
		}

		&-inst-wrap {
			display: flex;
			justify-content: center;
			padding: 10px 0;
			align-items: center;

		}
		&-inst-wrap-line {
			border: 1px solid gray;
			margin-left: 20px;
			margin-right: 20px;
			height: 40px;
		}

		&-stars {
			display: flex;
			align-items: end;
			justify-content: center;

			&-wrap {
				.react-stars {
					top: 3px !important;
				}
			}
		}
	}
	&__right {
		display: flex;
		justify-content: space-around;
		width: 25%;

		div {
			display: flex;
			align-items: end;
		}
	}

}

.one-blog__blog-short {
	margin: 10px 0;
}

.one-blog__blog-content {
	margin: 10px 0;

	&-head {
		margin: 10px 0;
	}
}

.one-blog__blog-content-head-ul {
	li {
		list-style-type: none
	}
}

.one-blog__blog-conent-body-wrap {
	margin: 10px 0;
}

.content-images-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;

	&__wrap {
		display: flex;
		width: 32.5%;
	}
}

.blog-content-image {
	height: 240px;
	width: 100%;
	object-fit: cover
}

.recomend-content_wrapper {
	display: flex;
	justify-content: space-between;
	width: 99%;
}

.recomend-content {
	display: flex;
	margin-top: 40px;
	flex-direction: column;

	span {
		font-size: 24px;
		color: #FFDDBA;
	}

	&_wrap {
		display: flex;
		margin: 20px 0;
		width: 33%;

		&-item {
			display: flex;
			flex-direction: column;

			position: relative;
			text-align: center;
			color: white;
			width: 100%;

			&-image {
				height: 250px;
				width: 100%;
				object-fit: cover;

			}

			&-body {
				display: flex;
				justify-content: space-between;
				position: relative;
			}

			&-date {
				padding: 5px 0;
				font-size: 12px;
				background: transparent;
				position: absolute;
				bottom: 0;
				right: 10px;
				width: 100%;
				margin-bottom: 10px;
				text-align: end;
			}

			&-title {
				padding: 0 15px 10px 0;
				color: #FFDDBA;
				font-size: 20px;
			}
			&-desc {
				padding: 5px 5px 0 0;
				font-size: 14px;
				color: #FFDDBA;

			}
		}
	}
}


@media screen and (max-width: 1024px) {
	.one-blog {
		width: 96%;

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	.leftImg {
		max-width: 100%;
		margin: 0 !important;
	}
	.another-blogs__item {
		a {
			width: 30%;
		}
	}
	.one-blog__item .rightImg {
		width: 100%;
		object-fit: cover;
	}
	.one-blog__title {
		text-align: center;
	}
	.one-blog__date {
		display: flex;
		justify-content: end;
	}
}

@media screen and (max-width: 700px) {
	.recomend-content_wrapper {
		flex-direction: column;
	}
	.recomend-content_wrap {
		width: 100%;
	}
	.recomend-content_wrap-item-image {
		height: unset;
	}
}

@media screen and (max-width: 2000px) {
	.recomend-content_wrap-item-image {
		height: 350px;
	}
}
@media screen and (max-width: 1400px) {
	.recomend-content_wrap-item-image {
		height: 250px;
	}
}

