.columns-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.colums-wrapper-noPhoto {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.columns {
  display: flex;
}

.columns-error {
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 300px;
}

.left-column {
  width: 50%;
  padding: 0 5px 0 10px;
}

.right-column {
  width: 50%;
  padding: 0 10px 0 5px;
}

.left-block {
  //grid-row-end: span 2;
  margin-bottom: 10px;
}

.right-block {
  grid-row-end: span 2;
  margin-bottom: 10px;
}

.selected-filter {
  display: none;

  &__nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    height: 65px;
    z-index: 3;

    &__back {
      display: flex;
      padding-left: 30px;
      align-items: center;

      &__text {
        border: none;
        padding-left: 5px;
      }
    }

    &__clear {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffddba;
      padding-right: 30px;
      border: none;
    }
  }
}

.img-wrapper {
  position: relative;
  display: block;

  div {
    width: 100%;
  }

  .top {
    display: flex;
    display: -webkit-flex;
  }

  .bottom {
    display: flex;
    display: -webkit-flex;
  }

  .small {
    width: 50% !important;
  }

  .adaptive {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 75%;

    &-small {
      //width: 98%;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: 47%;
      margin: 1px;
      //margin-left: 5px;
    }
  }

  img {
    display: block;
    //max-width: 99%;
    position: absolute;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.block_info {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 0 40px 40px;
  display: none;

  &-country,
  &-square,
  &-title {
    background: rgba(0, 0, 0, 0);
  }

  &-title {
    font-size: 36px;
    word-break: break-word;
  }
}

.content-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.img-wrapper:hover {
  .block_info {
    display: flex;
    display: -webkit-flex;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 600px) {
  .columns-wrapper {
    flex-direction: column;

    .left-column,
    .right-column {
      width: 100%;
    }
  }
  .columns {
    display: flex;
    flex-direction: column;
  }
  .grid-wrapper {
    display: flex;
    flex-direction: column;
    width: 97%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .img-wrapper {
    .block_info {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-end;
      height: fit-content !important;
      background: rgba(0, 0, 0, 0.7);
      padding: 15px;

      &-country,
      &-square {
        font-size: 12px;
      }

      &-title {
        font-size: 16px;
      }
    }
  }
  .img-wrapper {
    .block_info {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-end;
      height: fit-content !important;
      background: rgba(0, 0, 0, 0.7);
      padding: 15px;

      &-country,
      &-square {
        font-size: 12px;
      }

      &-title {
        font-size: 16px;
      }
    }
  }
  .selected-filter {
    display: flex;
  }
  .content-wrapper {
    display: grid;
  }
}

@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px) {
  .img-wrapper {
    .block_info {
      width: 100%;

      &-country,
      &-square {
        font-size: 10px;
      }

      &-title {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .img-wrapper {
    .block_info {
      width: 100%;

      &-country,
      &-square {
        font-size: 10px;
      }

      &-title {
        font-size: 12px;
      }
    }
  }
}
