@import 'src/variables';

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 75px;
  //width: 97.5%;
  width: 99%;
  padding: 0.5rem 0rem;
  background-color: #000;
  color: #fff;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.cselect {
  border: 1px solid black !important;
  outline: none !important;

  .css-1fdsijx-ValueContainer {
    color: red !important;
    padding: 8px 7px !important;
  }

  //div{
  //  div:nth-child(2){
  //    display: none !important;
  //
  //  }
  //}
}


.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  //margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
  //width: 59%;
  width: 69%;

  .selected {
    border-bottom: 1px solid  #ffddba;
    color:  #ffddba;
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  li {
    list-style-type: none;
    display: flex;
    align-self: center;
    //margin: 0 1rem;
    a {
      color: #fff;
      text-decoration: none;
      display: block;
      width: 100%;
    }
    a:hover {
      color: #ffddba;
    }
  }



  .css-13cymwt-control {
    background-color: black;
    border-style: none;
    box-shadow: none;
  }

  .css-t3ipsp-control {
    background-color: black;
    border-style: none;
    box-shadow: none;
  }

  .css-t3ipsp-control:hover {
    border: none;
  }

  .css-1nmdiq5-menu {
    margin: 0;
  }
}

.header__slider {
  display: none;
}

.select-language {
  border-radius: 0;
  outline: none;
  border: none;
  color: #ffddba;
  font-size: 16px;

  option {
    //color: #ffddba;
    //color: red;
    border-radius: 0;
    border: none;
    outline: none;
  }
}


.mobile-filter-btn {
  display: none;
}

.tablet-sel {
  display: none;
}

@media screen and (max-width: 1240px) {
  .navigation-menu {
    margin-left: auto;
    width: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .tablet-sel {
    display: block;
    position: absolute;
    left: 96.5%;
    top: 24px;
  }
  .navigation-menu {
    display: none;
  }
  .hamburger {
    top: 50%;
    left: 10%;
    width: 18px;
    height: 2px;
    background: $gold-color;
    position: absolute;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }

  .css-13cymwt-control {
    width: 70px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
  .css-t3ipsp-control {
    background-color: black;
    box-shadow: 0 0 0 1px black !important;
    background-color: black !important;
    border-color: black !important;
    box-shadow: black !important;
    bottom: 7px;
  }
  .css-t3ipsp-control:hover {
    border: none;
  }

  .css-1fdsijx-ValueContainer {
    width: 40px;
    border: none;
    border-style: none !important;
  }
  .css-1nmdiq5-menu {
    margin: 0 !important;
  }

  .css-1xgjblo-singleValue {
    width: 30px !important;
  }

  .cselect {
    .css-1fdsijx-ValueContainer {
      color: red !important;
      padding: 3px 7px 8px !important;
    }
  }

  .hamburger:before {
    top: -6px;
  }

  .hamburger:after {
    top: 6px;
  }

  .icon-three {
    position: absolute;
    bottom: 16px;
    left: 90% !important;
    width: 20px;
    height: 50px;
    cursor: pointer;
  }
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 84%;
    cursor: pointer;
    width: 28px;
  }
  .hamburger-three:before,
  .hamburger-three:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    background: $gold-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }

  .icon-three.active-three .hamburger-three {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  }

  .icon-three.active-three .hamburger-three:before {
    top: 0;
    transform: rotate(135deg);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }

  .icon-three.active-three .hamburger-three:after {
    top: 0;
    transform: rotate(225deg);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  .select-language {
    margin: 0 8% 0 0;
  }
  .header__slider {
    display: block;
    padding: 5px 0;
    height: 50px;
  }
}

.header__slider * {
  //height: 65px;

  .slick-slide,
  .slick-active,
  .slick-current {
    //max-width: 250px !important;
    //min-width: 220px !important;
    padding: 0 10px;
  }
}

// remove dotted firefox border
@-moz-document url-prefix() {
  select {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 #ffffff;
  }
}

.only-lang {
  //width: 98%;
  justify-content: space-between;

  .css-13cymwt-control {
    border-style: none;
    background-color: black;
  }

  .css-t3ipsp-control {
    border-style: none;
    background-color: black;
    box-shadow: none;
  }

  .css-13cymwt-control:hover {
    border: none;
  }

  .css-1nmdiq5-menu {
    margin: 0;
  }

  .css-13cymwt-control {
    left: 2%;
  }

  .css-1xgjblo-singleValue {
    width: 30px !important;
  }

  .css-b62m3t-container {
    top: 6px;
    left: 1%;
  }

  .icon-three {
    left: 75%;
  }
}

@media screem and (max-width: 900px) {
  .css-13cymwt-control {
    right: 9% !important;
  }
}

@media screen and (max-width: 800px) {
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 86%;
    cursor: pointer;
    width: 28px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 96%;
    top: 24px;
  }
  .icon-three {
    position: absolute;
    bottom: 16px;
    left: 91%;
    width: 20px;
    height: 50px;
    cursor: pointer;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 40px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 700px) {
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 86%;
    cursor: pointer;
    width: 28px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 95%;
    top: 26px;
  }
  .icon-three {
    position: absolute;
    bottom: 16px;
    left: 90%;
    width: 20px;
    height: 50px;
    cursor: pointer;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 30px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 620px) {
  .icon-three {
    // left: 92%;
    left: 90% !important;
    top: 8px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 95%;
    top: 24px;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 40px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 580px) {
  .header__slider {
    //display: block;
    //padding: 5px 0;
    //height: 60px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 560px) {
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 80%;
    cursor: pointer;
    width: 28px;
  }
  .icon-three {
    // left: 90%;
    left: 87% !important;
    top: 8px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 93%;
    top: 24px;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 40px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 490px) {
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 75%;
    cursor: pointer;
    width: 28px;
  }
  .icon-three {
    // left: 90%;
    left: 84% !important;
    top: 8px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 91%;
    top: 24px;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 30px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}

@media screen and (max-width: 400px) {
  .header__slider * {
    height: 65px;

    .slick-slide,
    .slick-active,
    .slick-current {
      //max-width: 250px !important;
      //min-width: 220px !important;
      padding: 0 10px;
      width: 130px !important;
    }
  }
}

@media screen and (max-width: 390px) {
  .mobile-filter-btn {
    display: block;
    position: absolute;
    left: 70%;
    cursor: pointer;
    width: 28px;
  }
  .icon-three {
    // left: 92%;
    left: 81% !important;
    top: 8px;
  }
  .tablet-sel {
    display: block;
    position: absolute;
    left: 89%;
    top: 24px;
  }
  .select-language {
    margin: 0;
  }
  .css-13cymwt-control {
    left: 2%;
  }
  .css-1xgjblo-singleValue {
    width: 30px !important;
  }
  .css-13cymwt-control {
    width: 40px;
    right: 15%;
    bottom: 7px;
    border: none;
    border-style: none !important;
    background-color: black !important;
  }
}
