@import 'src/variables';

.stage-number-large {
  display: flex;
  justify-content: center;
  border: 1px solid $gold-color;
  max-width: 142px;
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 20px;

  div {
    color: $gold-color;
  }
}

.stage-number {
  display: none;
}

.stage {
  margin: 30px 0;

  &-body {
    display: flex;
    justify-content: space-between;

    &__left {
      width: 48%;
      list-style-position: inside;
      overflow: hidden;
    }

    &__wrap {
      position: relative;
    }

    &__right {
      width: 49%;

      img {
        width: 564px;
        height: 430px;
        object-fit: cover;
      }
    }
  }

  .hide-button {
    display: none;
  }


  &-number {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 30px;

    div {
      background: white;
      color: #000;
      padding: 10px 40px;
      border-radius: 5px;
    }
  }

  &-title {
    font-size: 24px;
    color: rgba(255, 221, 186, 1);
    margin-bottom: 10px;
  }
}

.stage-text-more__txt {
  position: absolute;
  bottom: 3.5px;
  width: 120px;
  text-align: end;

  &-collapse {
    cursor: pointer;
    color: rgba(255, 221, 186, 1);
    font-size: 14px;
  }
}

.stage-text {
  max-height: 300px;
}
.stage-text-more {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 5px;

  &__txt {
    cursor: pointer;
    color: #FFDDBA;
    font-size: 14px;
  }
}

.stage-image {
  display: flex;
  justify-content: end;
}

.stages-wrapper {
  display: flex;
  flex-direction: column;
  width: 99%;
}

@media screen and (max-width: 1920px) {
  .stage {
    img {
      padding-left: 20px;
    }
  }
  .stage-body {
    display: flex;
    align-items: flex-start;

    &__right {
      img {
        width: 100% !important;
      }
    }
  }


  .more-info-item:nth-child(2) {
    border-left: 2px solid rgba(0, 0, 0, 0.7);
    border-right: 2px solid rgba(0, 0, 0, 0.7);
  }
}

@media screen and (max-width: 1420px) {
  .stage-body {

    &__right {
      img {
        object-fit: contain !important;
      }
    }
  }


  .more-info-item:nth-child(2) {
    border-left: 2px solid rgba(0, 0, 0, 0.7);
    border-right: 2px solid rgba(0, 0, 0, 0.7);
  }
}

@media screen and (max-width: 1024px) {
  .stage-body {
    align-items: flex-start;

    img {
      max-width: 100%;
      padding-left: 0;
    }
  }
  .stage-number {
    margin: 0 0 30px 10px;
  }
}


@media screen and (max-width: 800px) {
  .stage {
    margin: 0;
  }

  .stage-text {
    margin-bottom: 10px;
  }

  .stage-body__right {
    margin-bottom: 20px;
  }

  .stage-body__left {
    margin: 0;
  }

  .stage-image {
    margin-bottom: 10px;

    img {
      height: 20px;
    }
  }

  .stage-body__right {
    img {
      height: auto;
    }
  }

  .stage-body {
    flex-direction: column;
    width: 99%;
    margin: 0 auto;

    img {
      width: 100%;
      padding-left: 0;
    }

    &__left,
    &__right {
      width: 100%;

      img {
        max-width: 100%;
        margin: 0;
      }
    }
  }
  .stage-number {
    width: 95%;
    margin: 15px auto;
  }

  .stage-text-more {
    margin-bottom: 10px;
  }
  .stage-text-more__txt {
    bottom: 6.5px;
    height: 23px;
    display: flex;
    text-align: center;
    align-items: center;
    width: 130px;
    justify-content: end;
  }
}

@media screen and (max-width: 640px) {
  .stage-image {
    position: relative;

    img {
      height: 20px;
      width: 20px !important;
      position: absolute;
      bottom: 20px;
      color: #FFDDBA;
    }
  }
}

