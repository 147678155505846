@import 'src/variables';
.about-us {
	overflow: hidden;
	&__company {
		display: flex;
		padding-bottom: 50px;
		&__info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			// width: 30%;
			margin: 0 10px 0 5px;
			&__name {
				padding-bottom: 25px;

			}
		}
		&__example {
			width: 53%;
			padding: 0 20px;
			float: right;
		}
	}
	&__person {
		display: flex;
		&__photo {
			width: 30%;
			margin: 0 20px 0 5px;

			img {
				width: 100%;
			}
		}
		&__post {
			padding-bottom: 17px;
			color: $gold-color;
		}
		&__bio {
			width: 70%;
			padding: 0 27px 0 20px;
		}

		&__name {
			color: $gold-color;
			padding-bottom: 20px;
			font-size: 30px;
			font-weight: 700;
			line-height: 38px;
			text-align: left;
		}
	}
}
.about-us__company__info__name {
	color: #FFDDBA;
}
.about-us__person__post-mobile {
	display: none;
}
.partners-slider{
	width: 100%;
	max-width: 82vw;
	height: 170px
}
.content {
	overflow: hidden;
	//height: 520px;
	transition: height 0.3s ease;
}
.content.expanded {
	height: auto;
}
.about-us__company__info {
	p {
		padding-bottom: 20px !important;
	}
}
.about-us__company__info__textP {
	width: 97%;
	p {
		padding-bottom: 30px !important;
	}
}
.wrapper-blog {
	width: 100%;
}
@media screen and (max-width: 2847px) {
	.about-us__company__example {
		width: 54%;
	}
}
@media screen and (max-width: 2799px) {
	.about-us__company__example {
		width: 55%;
	}
}
@media screen and (max-width: 2753px) {
	.about-us__company__example {
		width: 56%;
	}
}
@media screen and (max-width: 2709px) {
	.about-us__company__example {
		width: 57%;
	}
}
@media screen and (max-width: 2666px) {
	.about-us__company__example {
		width: 58%;
	}
}
@media screen and (max-width: 2625px) {
	.about-us__company__example {
		width: 59%;
	}
}
@media screen and (max-width: 2586px) {
	.about-us__company__example {
		width: 60%;
	}
}
@media screen and (max-width: 2547px) {
	.about-us__company__example {
		width: 61%;
	}
}
@media screen and (max-width: 2510px) {
	.about-us__company__example {
		width: 59%;
	}
	.eight-image-block__item  {
		img {
			width: 417px !important;
			height: 557px !important;
		}

	}
}
@media screen and (max-width: 2469px) {
	.about-us__company__example {
		width: 60%;
	}
	.eight-image-block__item  {
		img {
			width: 417px !important;
			height: 557px !important;
		}

	}
}
@media screen and (max-width: 2433px) {
	.about-us__company__example {
		width: 59%;
	}
	.eight-image-block__item  {
		img {
			width: 402px !important;
			height: 537px !important;
		}

	}
}
@media screen and (max-width: 2392px) {
	.about-us__company__example {
		width: 60%;
	}
	.eight-image-block__item  {
		img {
			width: 402px !important;
			height: 537px !important;
		}

	}
}
@media screen and (max-width: 2357px) {
	.about-us__company__example {
		width: 59%;
	}
	.eight-image-block__item  {
		img {
			width: 388px !important;
			height: 517px !important;
		}

	}
}
@media screen and (max-width: 2321px) {
	.about-us__company__example {
		width: 60%;
	}
	.eight-image-block__item  {
		img {
			width: 388px !important;
			height: 517px !important;
		}

	}
}
@media screen and (max-width: 2287px) {
	.about-us__company__example {
		width: 60%;
	}
	.eight-image-block__item  {
		img {
			width: 380px !important;
			height: 507px !important;
		}

	}
}
@media screen and (max-width: 2247px) {
	.about-us__company__example {
		width: 61%;
	}
}
@media screen and (max-width: 2215px) {
	.about-us__company__example {
		width: 59%;
	}
	.eight-image-block__item  {
		img {
			width: 363px !important;
			height: 485px !important;
		}

	}
}
@media screen and (max-width: 2194px) {
	.about-us__company__example {
		width: 60%;
	}
	.eight-image-block__item  {
		img {
			width: 363px !important;
			height: 485px !important;
		}

	}
}
@media screen and (max-width: 1900px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 39px !important;
		}
		p:last-child {
			padding-bottom: 15px !important;
		}
	}
	.content {
		overflow: hidden;
		height: 0px;
		transition: height 0.3s ease;
	}
	.about-us__company__info__name {
		padding-bottom: 15px;
	}
}
@media screen and (max-width: 1885px) {
	.about-us__company__example {
		width: 62%;
	}
	.about-us__company__info__textP {
		p {
			padding-bottom: 28px !important;
		}
		p:last-child {
			padding-bottom: 15px !important;
		}
	}
}
@media screen and (max-width: 1750px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 23px !important;
		}
	}
}
@media screen and (max-width: 1680px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 27px !important;
		}
	}
}
@media screen and (max-width: 1630px) {
	.content {
		overflow: hidden;
		// height: 503px;
		transition: height 0.3s ease;
	}
	.about-us__company__info__textP {
		p {
			padding-bottom: 27px !important;
		}
	}
}
@media screen and (max-width: 1600px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 23px !important;
		}
	}
}
@media screen and (max-width: 1577px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 15px !important;
		}
	}
}
@media screen and (max-width: 1555px) {
	.content {
		overflow: hidden;
		// height: 522px;
		transition: height 0.3s ease;
	}
}
@media screen and (max-width: 1540px) {
	.content {
		overflow: hidden;
		// height: 519px;
		transition: height 0.3s ease;
	}
}
@media screen and (max-width: 1500px) {
	.content {
		overflow: hidden;
		// height: 520px;
		transition: height 0.3s ease;
	}
}
@media screen and (max-width: 1491px) {
	.about-us__company__info__textP {
		p {
			padding-bottom: 10px !important;
		}
	}
}
@media screen and (max-width: 1480px) {
	.content {
		overflow: hidden;
		// height: 521px;
		height: 0px;
		transition: height 0.3s ease;
	}
}
@media screen and (max-width: 1455px) {
	.content {
		overflow: hidden;
		// height: 522px;
		transition: height 0.3s ease;
	}
}
@media screen and (max-width: 1440px) {
	.content {
		overflow: hidden;
		height: 100px;
		transition: height 0.3s ease;
		margin-bottom: 10px;
	}
	.about-us__company-body {
		display: flex;
		margin-top: 20px;
	}
	.wrapper-blog {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
	}
	.about-us {
		&__company {
			display: flex;
			flex-direction: column;
			&__info {
				width: 100%;
				padding: 0 10px 0 10px;
			}
			&__example {
				width: 99%;
				padding: 0 10px 0 0;
			}
		}
		&__person {
			&__photo {
				width: 36%;
			}
			&__bio {
				padding: 0 27px 0 55px;
			}
		}
	}
}
@media screen and (max-width: 1393px) {
	.content {
		height: 100px;
	}
}
@media screen and (max-width: 1385px) {
	.content {
		overflow: hidden;
		height: 100px;
		transition: height 0.3s ease;
	}

	.about-us {
		&__company {
			&__info {
				width: 100%;
			}
			&__example {
				width: 104.5%;
			}
		}
		&__person {
			&__photo {
				width: 36%;
			}
			&__bio {
				padding: 0 27px 0 55px;
			}
		}
	}
}
@media screen and (max-width: 1379px) {
	.content {
		overflow: hidden;
		height: 100px;
		transition: height 0.3s ease;
	}

	.about-us {
		&__company {
			&__example {
				width: 104.5%;
			}
		}
		&__person {
			&__photo {
				width: 36%;
			}
			&__bio {
				padding: 0 27px 0 55px;
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.about-us__company__info__textP {
		width: 100%;
	}
}
@media screen and (max-width: 805px) {
	.content {
		height: 120px;
	}
}
@media screen and (max-width: 756px) {
	.content {
		height: 120px;
	}
}
@media screen and (max-width: 685px) {
	.content {
		height: 130px;
	}
}
@media screen and (max-width: 600px) {
	.about-us {
		&__company {
			margin-top: 30px;
			display: flex;
			flex-direction: column;
			&__info {
				width: 100%;
				margin: 0;
				padding-left: 10px;
				padding-right: 10px !important;
				&__name {
					padding-bottom: 10px;
				}
			}
			&__example {
				display: flex;
			}
		}
		&__person {
			flex-direction: column;
			&__name {
				padding-top: 20px;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-align: left;
			}
			&__photo {
				width: 100%;
				margin: 0;
				padding: 0 10px;
			}
			&__post {
				display: none;
			}
			&__bio {
				width: 100%;
				padding: 0 10px;
			}
		}
	}
	.about-us_mob {
		display: flex;
	}
	.about-us__person__post-mobile {
		font-size: 24px;
		font-weight: 600;
		//line-height: 20px;
		text-align: left;
		margin-bottom: 10px;
		color: #FFDDBA;
		display: block;
		padding: 0 10px;
	}
	.partners-slider{
		width: 100%;
		max-width: 100vw;
		height: 170px
	}
	.content {
		height: 150px;
	}
	.wrapper-blog {
		margin-top: 10px;
	}
	.wrapper-blog {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
@media screen and (max-width: 495px) {
	.content {
		height: 170px;
	}
}
@media screen and (max-width: 461px) {
	.content {
		height: 190px;
	}
}
@media screen and (max-width: 441px) {
	.content {
		height: 200px;
	}
}
@media screen and (max-width: 420px) {
	.content {
		height: 220px;
	}
}
@media screen and (max-width: 399px) {
	.content {
		height: 230px;
	}
}
@media screen and (max-width: 355px) {
	.content {
		height: 250px;
	}
}
@media screen and (max-width: 320px) {
	.content {
		height: 280px;
	}
}
