.loader {
	position: absolute;
	height: 100svh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.wrap {
	display: flex;
	position: relative;
	justify-content: center;
	//filter: sepia(1);
	width: 100px;
	height: 100px;
	animation: spin 1s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
