.more-info {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 40px;
    background: #FFDDBA;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: black;
    font-size: 14px;
    width: 35%;
    text-align: center;
    cursor: pointer;
  }
}

.head-repair {
  padding: 0 10px;
}

.modal-overlay-more {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-more-info {
  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  width: 550px;

  &-close-wrap {
    background: white;
    display: flex;
    justify-content: end;

    &__btn {
      background: white;
      color: black;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  &-wrapper {

    &__head {
      background: white;
      color: black;
      font-size: 18px;
    }

    &__body {
      background: white;

      &-up {
        background: white;
        display: flex;
        justify-content: space-between;

        input {
          width: 49%;
          background: white;
          outline: none;
          height: 35px;
          padding: 5px;
          border: 1px solid black;
          margin-top: 10px;
          color: black;
        }
      }

      &-down {
        background: white;

        textarea {
          width: 100%;
          height: 150px;
          margin-top: 10px;
          padding: 5px;
          background: white;
          border: 1px solid black;
          resize: none;
          color: black;
        }

      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    background: white;
    padding-top: 10px;

    &__cancel {
      background: white;
      color: black;
      height: 30px;
      padding: 5px 10px;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &__send {
      background: #FFDDBA;
      color: black;
      height: 30px;
      padding: 5px 10px;
      border: 1px solid black;
      border-radius: 5px;
      border: none;
      outline: none;
      margin-left: 20px;
      cursor: pointer;
      font-size: 16px;

    }
  }

  &__check {
    display: flex;
    padding: 20px 0 10px;
    background: white;

    &-text {
      background: white;
      color: black;
    }

    &-wrap {
      display: flex;
      background: white;
      margin-left: 15px;

      label {
        background: white;
        color: black;
        margin-left: 5px;
      }

      input[type='checkbox'] {
        accent-color: #FFDDBA;
      }
    }
  }
}

.repair-head-text {
  padding: 38px 20px 20px 0;
  color: rgba(255, 221, 186, 1);
  font-size: 30px
}

@media screen and (max-width: 640px) {
  .repair-head-text {
    font-size: 20px;
    padding: 0 10px;
  }
}

@media screen and (max-width: 460px) {
  .more-info {
    button {
      width: 50%;
    }
  }
}
