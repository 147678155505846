@import 'src/variables';

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Gothic A1, sans-serif !important;
	background: #000;
	color: #fff;

	p {
		padding-bottom: 15px;
		font-size: calc(0.9em + 0.1vw) !important;
	}
}

//.checkmark {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 16px;
//  width: 16px;
//  border-radius: 50%;
//  background-color: #eee;
//}
//
///* Hide the browser's default checkbox */
//.container input {
//  position: absolute;
//  opacity: 0;
//  cursor: pointer;
//  height: 0;
//  width: 0;
//}
//
/////* Create a custom checkbox */
//.checkmark {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 25px;
//  width: 25px;
//  background-color: #eee;
//}
//
///* On mouse-over, add a grey background color */
//.filter-block:hover input ~ .checkmark {
//  background-color: $gold-color;
//}
//
///* When the checkbox is checked, add a blue background */
//.filter-block input:checked ~ .checkmark {
//  background-color: $gold-color;
//}
//
///* Create the checkmark/indicator (hidden when not checked) */
//.checkmark:after {
//  content: "";
//  position: absolute;
//  display: none;
//}
//
///* Show the checkmark when checked */
//.filter-block input:checked ~ .checkmark:after {
//  display: block;
//}
//
///* Style the checkmark/indicator */
//.filter-block .checkmark:after {
//  left: 9px;
//  top: 5px;
//  width: 5px;
//  height: 10px;
//  border: solid white;
//  border-width: 0 3px 3px 0;
//  -webkit-transform: rotate(45deg);
//  -ms-transform: rotate(45deg);
//  transform: rotate(45deg);
//}
