@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

//.slick-list, .slick-track, .slick-slide, .slick-cloned {
//  height: 30px
//}
//
//.slick-slide {
//  padding: 0 20px;
//}
//
//.slick-slider {
//  //width: 100%;
//}
//
//.slick-slide .slick-active, .slick-cloned {
//  padding: 0 20px;
//
//}
//
.read-more {
	cursor: pointer;
	color: rgba(255, 221, 186, 1);
	//font-size: 13px;
}
.design {
	text-decoration: none;
	//height: 40px;
	//width: fit-content;
	//justify-content: center;
}
.selectedType {
	border-bottom: 1px solid #fff;
}
//.slick-track * {
//	.slick-slide .slick-active .slick-center .slick-current {
//		width: 120px;
//	}
//}
