@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

//.slick-slide {
//	height: 170px;
//}

.slick-slide img {
	height: 170px;
}

.slick-slider {
	width: 100%;
	//margin: 0 auto;
}

.slick-prev {
	z-index: 10;
	left: 0;
}
.slick-next {
	z-index: 10;
	right: 25px;
}

.slick-prev:before,
.slick-next:before {
	font-size: 45px;
	opacity: 1;
}
.picture-slide{
	margin:0 5px;
	cursor: pointer;
}

.slick-prev:before, .slick-next:before { font-family: "slick";  font-size: 80px; line-height: 1; color: rgb(255, 221, 186); opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev:before { content: "‹"; }
[dir="rtl"] .slick-prev:before { content: "›"; }

[dir="rtl"] .slick-next { left: -10px; top: 70px; right: auto; }
.slick-next:before { content: "›"; }
[dir="rtl"] .slick-next:before { content: "‹"; }

.slick-prev, .slick-next {
	top: 23% !important;
	margin-left: 18px;
}
.slider__wrapper {
	.slick-list {
		width: 90%;
		margin: 0 auto;
	}

}
