@import 'src/variables';

.blogs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  &-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0;

    &_left {
      display: flex;
      flex-direction: column;
      width: 48%;

      &-more {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 5px 0;
      }

      &-title {
        font-size: 24px;
        color: rgb(255, 221, 186);
        margin-bottom: 10px;
      }
    }

    &_right {
      width: 49%;
      margin: 0 10px 0 0;

      &-image {
        max-width: 100%;
        height: 470px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 0 0 20px;
  }

  &-title {
    color: $gold-color;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
  }
}

.desc {
  p {
    padding-bottom: 15px;
  }
}

.show-blogs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  border: 2px solid #FFDDBA;
  width: 90%;
}

.blog {
  width: 23%;
  margin-right: 2%;
  position: relative;

  video {
    width: 100%;
    height: 77%;

    -webkit-transform: scaleX(1.2);
  }

  .video-play__btn {
    position: absolute;
    top: 30%;
    left: 37%;
    background: transparent;
  }

  &-image {
    width: 100%;
    height: 77%;
    padding-bottom: 18px;
  }

  &-date {
    padding-top: 5px;
    font-size: 14px;
  }

  &-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 1215px) {
  .blogs-wrap_left-more {
    margin: 5px 0 5px 0;
  }
}

@media screen and (max-width: 1100px) {
  .blogs-wrap_left-more {
    margin: 0 0 5px 0;
    padding: 5px 0;
  }
}

@media screen and (max-width: 1024px) {
  .blogs {
    display: flex;

    &-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0 0 0 20px;
    }
  }

  .blogs-wrap_right-image {
    max-width: 100%;
    padding-left: 0;
    object-fit: cover;
  }

  .desc p {
    padding-bottom: 15px
  }
}
@media screen and (max-width: 600px) {

  .blog {
    width: 48%;
  }

  .blogs-wrapper {
    padding: 0 10px;
  }

  .blogs-wrap {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    position: relative;
  }

  .blogs-wrap_left {
    display: flex;
    width: 99%;
  }

  .blogs-wrap_right {
    width: 100%;
  }
  .blogs-wrap_left-title {
    text-align: center;
    margin-top: 10px;
    position: absolute;
    font-size: 20px;
    width: 100%;
    padding: 10px;
    bottom: 83px;
    background: #000000b3;
  }

}

@media screen and (max-width: 400px) {
  .blogs {
    display: flex;

    &-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0 0 0 10px;
    }
  }
}
