.eight-image-block {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 33.3%;
    img {
      width: 100%;
      height: 587px;
      object-fit: cover;
      padding: 2px;
    }
  }
}
@media screen and (max-width: 2170px) {
  .about-us__company__example {
    width: 59%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 349px !important;
        height: 465px !important;
      }
    }
  }
}
@media screen and (max-width: 2142px) {
  .about-us__company__example {
    width: 59%;
  }
}
@media screen and (max-width: 2124px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 2092px) {
  .about-us__company__example {
    width: 57%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 326px !important;
        height: 435px !important;
      }
    }
  }
}
@media screen and (max-width: 2066px) {
  .about-us__company__example {
    width: 58%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 326px !important;
        height: 435px !important;
      }
    }
  }
}
@media screen and (max-width: 2036px) {
  .about-us__company__example {
    width: 59%;
  }
}
@media screen and (max-width: 2022px) {
  .about-us__company__example {
    width: 55%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 303px !important;
        height: 405px !important;
      }
    }
  }
}
@media screen and (max-width: 2008px) {
  .about-us__company__example {
    width: 56%;
  }
}
@media screen and (max-width: 1975px) {
  .about-us__company__example {
    width: 57%;
  }
}
@media screen and (max-width: 1970px) {
  .about-us__company__example {
    width: 57%;
  }
}
@media screen and (max-width: 1960px) {
  .about-us__company__example {
    width: 57%;
  }
}
@media screen and (max-width: 1950px) {
  .about-us__company__example {
    width: 58%;
  }
}
@media screen and (max-width: 1918px) {
  .about-us__company__example {
    width: 59.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 305px;
        height: 406px;
      }
    }
  }
}
@media screen and (max-width: 1898px) {
  .about-us__company__example {
    width: 60%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 305px;
        height: 406px;
      }
    }
  }
}
@media screen and (max-width: 1887px) {
  .about-us__company__example {
    width: 60.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 305px;
        height: 406px;
      }
    }
  }
}
@media screen and (max-width: 1872px) {
  .about-us__company__example {
    width: 61%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 305px;
        height: 406px;
      }
    }
  }
}
@media screen and (max-width: 1860px) {
  .about-us__company__example {
    width: 61.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 305px;
        height: 406px;
      }
    }
  }
}
@media screen and (max-width: 1848px) {
  .about-us__company__example {
    width: 61%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1835px) {
  .about-us__company__example {
    width: 61.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1824px) {
  .about-us__company__example {
    width: 62%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1812px) {
  .about-us__company__example {
    width: 62.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1808px) {
  .about-us__company__example {
    width: 63%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 1796px) {
  .about-us__company__example {
    width: 59%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 282px !important;
        height: 375px !important;
      }
    }
  }
}
@media screen and (max-width: 1792px) {
  .about-us__company__example {
    width: 60%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 282px !important;
        height: 375px !important;
      }
    }
  }
}
@media screen and (max-width: 1770px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 1758px) {
  .about-us__company__example {
    width: 61%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 280px;
        height: 373px;
      }
    }
  }
}
@media screen and (max-width: 1745px) {
  .about-us__company__example {
    width: 61.5%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 280px;
        height: 373px;
      }
    }
  }
}
@media screen and (max-width: 1745px) {
  .about-us__company__example {
    width: 62%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 280px;
        height: 373px;
      }
    }
  }
}
@media screen and (max-width: 1722px) {
  .about-us__company__example {
    width: 62%;
  }
}
@media screen and (max-width: 1711px) {
  .about-us__company__example {
    width: 58%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 259px !important;
        height: 345px !important;
      }
    }
  }
}
@media screen and (max-width: 1698px) {
  .about-us__company__example {
    width: 58%;
  }
}
@media screen and (max-width: 1690px) {
  .about-us__company__example {
    width: 59%;
  }
}
@media screen and (max-width: 1665px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 1662px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 1642px) {
  .about-us__company__example {
    width: 61%;
  }
}
@media screen and (max-width: 1636px) {
  .about-us__company__example {
    width: 62%;
  }
}
@media screen and (max-width: 1625px) {
  .about-us__company__example {
    width: 62.5%;
  }
}
@media screen and (max-width: 1614px) {
  .about-us__company__example {
    width: 63%;
  }
}
@media screen and (max-width: 1604px) {
  .about-us__company__example {
    width: 63.5%;
  }
}
@media screen and (max-width: 1593px) {
  .about-us__company__example {
    width: 63%;
  }
}
@media screen and (max-width: 1577px) {
  .about-us__company__example {
    width: 58%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 236px !important;
        height: 315px !important;
      }
    }
  }
}
@media screen and (max-width: 1570px) {
  .about-us__company__example {
    width: 59%;
  }
}
@media screen and (max-width: 1566px) {
  .about-us__company__example {
    width: 59%;
  }
}
@media screen and (max-width: 1548px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 1538px) {
  .about-us__company__example {
    width: 60%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 230px;
        height: 306px;
      }
    }
  }
}
@media screen and (max-width: 1527px) {
  .about-us__company__example {
    width: 61%;
  }
}
@media screen and (max-width: 1507px) {
  .about-us__company__example {
    width: 62%;
  }
}
@media screen and (max-width: 1490px) {
  .about-us__company__example {
    width: 62%;
  }
}
@media screen and (max-width: 1487px) {
  .about-us__company__example {
    width: 59%;
  }
  .eight-image-block {
    &__item {
      img {
        width: 221px !important;
        height: 295px !important;
      }
    }
  }
}
@media screen and (max-width: 1472px) {
  .about-us__company__example {
    width: 60%;
  }
}
@media screen and (max-width: 1460px) {
  .about-us__company__example {
    width: 61%;
  }
}
@media screen and (max-width: 1450px) {
  .about-us__company__example {
    width: 61.5%;
  }
}
@media screen and (max-width: 1440px) {
  .about-us__company__example {
    width: 100%;
  }
  .eight-image-block {
    width: 100%;

    &__item {
      width: 33.3%;
      padding-bottom: 0;
      img {
        width: 100% !important;
        height: 354px !important;
        //object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .eight-image-block {
    &__item {
      img {
        height: 290px;
      }
    }
  }
}
@media screen and (max-width: 1260px) {
  .eight-image-block {
    width: 100%;

    &__item {
    width: 33.3%;
    height: auto;
      img {
        max-width: 100%;
        height: 230px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .eight-image-block {
    width: 100%;

    &__item {
    width: 33.3%;
    height: auto;
      img {
        max-width: 100%;
        height: 180px;
        object-fit: cover;
      }
    }

  }
}
@media screen and (max-width: 1024px) {
  .eight-image-block {

    &__item {
      img {
        max-width: 100%;
        height: 190px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 730px) {
  .eight-image-block {

    &__item {
      img {
        max-width: 100%;
        height: 180px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 670px) {
  .eight-image-block {

    &__item {
      img {
        max-width: 100%;
        height: 170px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .eight-image-block {
    &__item {
      width: 33.3%;
      display: flex;
      justify-content: space-between;

      img {
        width: 194px !important;
        height: 258px !important;
      }
    }
  }
  .about-us__company {
    padding-bottom: 20px;
  }
  .about-us__company__example {
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 500px) {
  .eight-image-block {
    &__item {
      img {
        width: 172px !important;
        height: 230px !important;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .eight-image-block {
    &__item {
      img {
        width: 150px !important;
        height: 200px !important;
      }
    }
  }
}
@media screen and (max-width: 380px) {
  .eight-image-block {
    &__item {
      img {
        width: 120px !important;
        height: 160px !important;
      }
    }
  }
}
