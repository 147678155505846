.not-found {

  &__wrap {
    display: flex;
    background: white;
    width: 98%;
    border-radius: 5px;
  }

  &__wrap-info {
    //width: 98%;
    background: white;
    text-align: center;
    border-radius: 5px;
    border: 1px solid white;

    &-name {
      background: white;
      color: black;
      padding-top: 10px;
    }

    &-back {
      background: white;
      display: flex;
      justify-content: center;

      &-text {
        background: #FFDDBA;
        color: black;
        width: 250px;
        height: 30px;
        outline: none;
        padding: 5px;
        border: none;
        border-radius: 5px;
        margin: 10px 0;
        cursor: pointer;
        text-decoration: none;
      }
    }

    &-image {
      background: white;
      img {
        width: 90%;
      }
    }
  }
}