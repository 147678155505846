.contacts {
  //min-width: 1000px;
  margin: 0 auto;
  flex: auto;
  max-width: 84%;
  overflow: hidden;

  &__title {
    color: #ffddba;
    padding-bottom: 15px;
  }

  &__list {
    //padding: 15px ;
    padding-bottom: 40px;

    li {
      padding: 10px 0;
      font-size: 14px;
      font-weight: bold;
      list-style-type: none;

      //&:before {
      //  content: '•';
      //  font-size: 30px;
      //  line-height: 24px;
      //  vertical-align: middle;
      //  padding-right: 9px;
      //}

      a {
        text-decoration: none;
        font-weight: 500;
      }
    }
  }

  &-our-projects {
    padding: 50px 0 20px 10px;
    color: #ffddba;
  }

  &__btn-write {
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    background: #ffddba;
    color: black;
    font-size: 14px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px 40px;
  border-radius: 8px;

  &-wrapper {

    &__head {
      background: white;
      color: black;
      font-size: 18px;
    }

    &__body {
      background: white;

      &-up {
        background: white;
        display: flex;
        justify-content: space-between;

        input {
          width: 49%;
          background: white;
          outline: none;
          height: 35px;
          padding: 5px;
          border: 1px solid black;
          margin-top: 10px;
          color: black;
        }
      }

      &-down {
        background: white;

        textarea {
          width: 100%;
          height: 150px;
          margin-top: 10px;
          padding: 5px;
          background: white;
          border: 1px solid black;
          resize: none;
          color: black;
        }

      }
    }
  }

  &-footer {
    display: flex;
    justify-content: end;
    background: white;
    padding-top: 10px;

    &__cancel {
      background: white;
      color: black;
      height: 30px;
      padding: 5px 10px;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &__send {
      background: #FFDDBA;
      color: black;
      height: 30px;
      padding: 5px 10px;
      border: 1px solid black;
      border-radius: 5px;
      border: none;
      outline: none;
      margin-left: 20px;
      cursor: pointer;

    }
  }
}



//.close-wrap {
//  background: white;
//  display: flex;
//  justify-content: end;
//
//  &__btn {
//    font-size: 20px;
//    cursor: pointer;
//    display: flex;
//    justify-content: end;
//    color: black;
//    background: white;
//    height: 15px;
//    width: 15px;
//  }
//}

//.slick-list {
//  height: 180px;
//}

.slider__wrapper {
  //width: 98%;
  height: 250px;

  .picture-slide {
    object-fit: cover;
  }

  .slick-slide div, .slick-active div, .slick-current div {
    height: 226px;
  }

  .slick-prev, .slick-next {
    top: 29% !important;
  }
}

.maps {
  max-width: 100%;
}

@media screen and (max-width: 400px) {
  .contacts {
    &-our-projects {
      padding: 50px 0 20px 10px;
      color: #ffddba;
    }
  }
}

.error-modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  height: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.error-modal-overlay__text-wrap {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  color: red;
}

.success-modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  height: 180px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  &__close-head {
    background-color: white;
    display: flex;
    justify-content: end;

    &-img{
      background-color: white;
      color: black;
      display: flex;
      align-items: end;
      justify-content: end;
      cursor: pointer;
    }
  }

  &__text-wrap {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    div {
      background-color: white;
      color: black;
    }
  }

  p {
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #0056b3;
  }
}
