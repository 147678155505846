.triple {
	&__row-1 {
		&__item {
			padding-bottom: 35%;
			width: 50% !important;
		}
	}

	&__row-2 {
		&__item {
			padding-bottom: 68%;
		}
	}
	&__row-2,
	&__row-1 {
		display: flex;
		justify-content: space-between;
		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin: 1px;
		}
	}
}

.triple {
	&__row-1-spec {
		&__item {
			width: 50% !important;

			img {
				display: block;
				position: unset;
				object-fit: cover;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 450px;
			}
		}
	}

	&__row-2-spec {
		&__item {
			padding-bottom: 68%;
		}
	}
	&__row-2-spec,
	&__row-1-spec {
		display: flex;
		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin: 1px;
		}
	}
}

.triple__row-1__item-skeleton {
	background-color: gray;
	padding-bottom: 35%;
	width: 49.8% !important;
	
}

.triple__row-2__item-skeleton {
	background-color: gray;
	padding-bottom: 68%;
}
