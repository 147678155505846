.outlet-wrap{
    display: flex;
    width: 99%;
    justify-content: space-between;

    &__wrapper {
        width: 24%;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
    }
    &__wrapper-item {
        display: flex;
        justify-content: center;
        background: #000;
        color: white;
        font-size: 14px;
        border-bottom: 1px solid white;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-align: center;
    }

    &__wrapper-item-con {
        display: flex;
        justify-content: center;
        background: #000;
        color: #FFDDBA;
        padding: 10px 0;
        font-size: 14px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.outlet-wrap__wrapper-attributes-price {
    color: #FFDDBA
}

.outlet-head-text {
    padding: 30px 20px 20px 0;
    font-size: 30px;
    color: rgba(255, 221, 186, 1);
}

@media screen and (max-width: 768px) {
    .outlet-wrap{
        display: flex;
        width: 98%;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: row;
        margin-left: 20px;

        &__wrapper {
            width: calc(33% - 20px);;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            margin: 0 20px 40px 0;

        }
        &__wrapper-item {
            display: flex;
            justify-content: center;
            //background: white;
            color: white;
            font-size: 12px;
            border-bottom: 1px solid white;
            padding: 8px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
        }
        .outlet-wrap__wrapper-attributes-price {
            color: #FFDDBA;
            padding-left: 5px;
        }

        &__wrapper-item-con {
            display: flex;
            justify-content: center;
            color: #FFDDBA;
            padding: 13px;
            font-size: 14px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
  }

@media screen and (max-width: 640px) {
    .outlet-head-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 576px) {
    .outlet-wrap{
        display: flex;
        width: 98%;
        margin: 0 auto;
        flex-direction: column;
        align-items: center;

        &__wrapper {
            width: 95%;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            margin: 20px 0 20px 0;
        }
        .outlet-wrap__wrapper-attributes-price {
            color: #FFDDBA;
            padding-left: 5px;
            background: black;
        }
        &__wrapper-item {
            display: flex;
            justify-content: center;
            background: #000;
            color: white;
            font-size: 14px;
            border-bottom: 1px solid #000;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &__wrapper-item-con {
            display: flex;
            justify-content: center;
            background: #000;
            color: #FFDDBA;
            padding: 13px;
            font-size: 14px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
  }
