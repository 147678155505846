.question-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__head {
    display: flex;
    text-align: center;
    font-size: 24px;
    color: #FFDDBA;
    margin-bottom: 20px;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 95%;

    &-quest {
      background: #FFDDBA;
      padding: 5px;
      color: black;
    }

    &-info {
      width: 80%;
      margin: 20px 5px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .question-wrapper {
    width: 98%;
  }
}
