.wrapper-filter {
  display: flex;
  border-bottom: 1px solid white;
  height: 50px;
}
.wrap-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  text-decoration: none;
  width: 100%;
  padding-right: 20px;
}

.wrap-filter-text {
  display: flex;
  align-items: center;
  align-self: center;
}

.wrap-filter-img {
  height: 22px;
  width: 22px;
}
