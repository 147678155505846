@import "src/variables";

.footer {
  background-size: 100% 100%;
  width: 100%;
  height: 120px;
&__img{
  width: 100%;
  padding-top: 30px;
}
  &-body {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 10px 20px;
    margin-top: 1px;

    &__left {
      p {
        display: flex;
        align-items: center;
      }

      .footer-contact {
        padding-left: 5px;

        a {
          padding-left: 15px;
        }
      }

      .footer-contact:nth-child(3){
        padding-bottom: 25px;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-terms {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        cursor: pointer;
        color: #ffddba;
        text-decoration: none;
      }

    }
  }

  &__title {
    color: $gold-color;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  &-telephone, &-email {
    font-size: 10pt;
    padding-bottom: 5px;
    text-decoration: none;
  }

}


.footer-call-wrapper {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 997;
  }

  .overlay.show {
    display: block;
  }

  .footer-wrap-call {
    display: flex;
    flex-direction: column;
  }

  .comun-text {
    text-align: center;
    font-size: 14px;
    background: #ffddba;
    font-weight: 700;
    color: black;
    border-radius: 50%;

  }

  .footer-call {
    background: #ffddba !important;
    width: 56px;
    height: 56px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    position: fixed;
    bottom: 15px;
    left: 10px;
    cursor: pointer;
    z-index: 999;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0 0 0 0 #ffddba;
    -webkit-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
    font-size: 22px;
    font-weight: normal;
    font-family: sans-serif;
    text-decoration: none !important;
    color: #ffffff;
    transition: all 300ms ease-in-out;

    svg {
      font-size: 30px;
      height: 29px;
      width: 29px;
      background: top;


      path {
        fill: black;
      }
    }
  }

  .footer-call:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: #ffffff;
  }

  .footer-call-item {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 75px;
    left: 20px;
    background: top;
    justify-content: center;
    align-items: center;
    z-index: 998;
  }

  .footer-call-item-block {
    display: block;
  }

  .footer-call-right-info {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    left: 42px;
    color: black;
    width: 180px;
    padding: 10px 15px;
    padding-left: 45px;
    font-size: 12px;
    background: white;
    justify-content: center;
    align-items: center;
    z-index: 998;
    line-height: 17px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
  }

  .info-title {
    display: none;
  }

  .info-title-mail {
    display: none;
  }

  .info-title-wp {
    display: none;
  }

  .info-title-tg {
    display: none;
  }

  .info-title-vk {
    display: none;
  }

  .icon-wrap {
    position: relative;
    cursor: pointer;
  }

  .icon-wrap:hover .info-title {
    display: flex;
    align-items: center;
    position: absolute;
    top: 9px;
    right: -158px;
    background: white;
    color: black;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
  }

  .icon-wrap:hover .info-title-mail {
    display: flex;
    align-items: center;
    position: absolute;
    top: 9px;
    right: -73px;
    background: white;
    color: black;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
  }

  .icon-wrap:hover .info-title-wp {
    display: flex;
    align-items: center;
    position: absolute;
    top: 9px;
    right: -98px;
    background: white;
    color: black;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
  }

  .icon-wrap:hover .info-title-tg {
    display: flex;
    align-items: center;
    position: absolute;
    top: 9px;
    right: -95px;
    background: white;
    color: black;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
  }

  .icon-wrap:hover .info-title-vk {
    display: flex;
    align-items: center;
    position: absolute;
    top: 9px;
    right: -95px;
    background: white;
    color: black;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
  }

  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  .footer-call-item-wrap {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: #ffddba;
    margin-bottom: 10px;

    svg {
      font-size: 31px;
      background: top;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin: 8px auto;
      padding-left: 1px;

      path {
        fill: black;
      }
    }
  }

  .footer-call-item-wrap:hover {
    box-shadow: 1px 2px 5px #ffddba;
    transition: 0.3s;
  }


  .footer-call-item-wrap-tel {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: #ffddba;
    margin-bottom: 10px;

    svg {
      font-size: 25px;
      background: top;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin: 10px auto;

      path {
        fill: black;
      }
    }
  }

  .footer-call-item-wrap-tel:hover {
    box-shadow: 1px 2px 5px #ffddba;
    transition: 0.3s;
  }

  .footer-call-item-wrap-mail {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: #ffddba;
    margin-bottom: 10px;

    svg {
      font-size: 25px;
      background: top;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin: 10px auto;

      path {
        fill: black;
      }
    }
  }

  .footer-call-item-wrap-mail:hover {
    box-shadow: 1px 2px 5px #ffddba;
    transition: 0.3s;
  }


}

@media screen and (max-width: 1024px) {
  .footer-wrap {
    display: none;
  }
}


.scroll-to-top-button {
  position: fixed;
  bottom: 70px;
  right: 10px;
  display: none;
  padding: 10px;
  background-color: #ffddba !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #ffddba !important;
  }

  &.visible {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .scroll-to-top-button {
    right: 10px;
  }
}
